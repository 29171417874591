import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
//translation
import { TranslateFakeLoader, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';

//service

//material design
// import { MatSelectModule } from '@angular/material/select';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatIconModule } from '@angular/material';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { PagesModule } from './pages/pages.module';
import { AgGridModule } from 'ag-grid-angular';
// import 'ag-grid-enterprise';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { BreadcrumsComponent } from './layout/breadcrums/breadcrums.component';
import { MaterialModule } from './material.module';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptor } from './auth.interceptor';
import { UserService } from './_services/user.service';
import { InterceptorService } from './_services/interceptor.service';

const oktaAuth = new OktaAuth({
    //UAT settings
    // issuer: 'https://genpact.oktapreview.com/oauth2/default',
    // clientId: '0oa3veyh379vGOTal0x7',

    //PROD settings
    issuer: 'https://genpact.okta-emea.com/oauth2/default',
    clientId: '0oa85qfs62Qqnwcc40i7',

    // redirectUri: 'http://localhost:4200/login/callback',
    // logoutUrl: 'http://localhost:4200/login',


    //UAT URL-Front door

    // OLD URLs
    // redirectUri: 'https://gch-fd-dev-wmt-001.azurefd.net/login/callback',
    // logoutUrl: 'https://gch-fd-dev-wmt-001.azurefd.net/login',

    // NEW URLs 1
    // redirectUri: 'https://ppatool-dev.genpact.com/login/callback',
    // logoutUrl: 'https://ppatool-dev.genpact.com/login',

    // NEW URLs 2
    // redirectUri: 'https://gch-wap-eus-dev-digital-walmart-01.azurewebsites.net/login/callback',
    // logoutUrl: 'https://gch-wap-eus-dev-digital-walmart-01.azurewebsites.net/login',

    //Prod URL-
    redirectUri: 'https://ppatool.genpact.com/login/callback',
    logoutUrl:   'https://ppatool.genpact.com/login',

    //Prod URL- Front Door
    // redirectUri: 'https://gch-wap-eus-prod-walmart-02.azurewebsites.net/login/callback',
    // logoutUrl:   'https://gch-wap-eus-prod-walmart-02.azurewebsites.net/login',

    //UAT URL-Appservice URL
    // redirectUri: 'https://pptwalmartdev.azurewebsites.net/login/callback',
    // logoutUrl:   'https://pptwalmartdev.azurewebsites.net/login',

    scopes: ['email', 'profile', 'openid', 'offline_access'],
    pkce: true
    // ignoreLifetime: true,
    // maxClockSkew: 3620
});

@NgModule({
    declarations: [AppComponent],
    imports: [
        PagesModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        MaterialModule,
        OktaAuthModule,
        NgZorroAntdModule,
        // Ng4LoadingSpinnerModule.forRoot()
        // MatSelectModule,
        // MatFormFieldModule,
        // MatIconModule
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [MaterialModule, NgZorroAntdModule],
    providers: [
        UserService,
        TranslateService,
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptorService,
        //     multi: true
        // },
        { provide: NZ_I18N, useValue: en_US }
    ], //
    bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function translateLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
