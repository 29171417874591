import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError as observableThrowError, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { catchError } from 'rxjs/operators';
import { LoginUser, Role } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LoginUser>;
    public currentUser: Observable<LoginUser>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<LoginUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public val: any[] = [];
    public get currentUserValue(): LoginUser {
        return this.currentUserSubject.value;
    }


    login() {  
        return this.http.get<any>('https://ppatool-api.genpact.com/getUserRoleById').pipe(
            map((data) => {
                let user;

                if (data[0]?.ROLE_NAME) {  
                    let r: Role;

                    switch (data[0].ROLE_NAME.toLowerCase()) {
                        case 'auditor':
                            r = Role.Auditor;
                            break;
                        case 'ppamanager':
                            r = Role.Manager;
                            break;
                        case 'applicationadmin':
                            r = Role.ApplicationAdmin;
                            break;
                        case 'qaadmin':
                            r = Role.QAAdmin;
                            break;
                        case 'admin':
                            r = Role.Admin;
                            break;
                        case 'systemuser':
                            r = Role.SystemUser;
                            break;
                    }

                    user = {
                        name: data[0].name,
                        role: r,
                        token: 'fake-jwt-token'
                        
                    };
                }

                if (user && user.token) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return data;
            })
        );
    }

    logout() {
        // Clear the current user from local storage to log out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);

        
    }

    errorHandler(error: HttpErrorResponse) {
        return observableThrowError(error.message || 'Server Error');
    }
}
        

            
            