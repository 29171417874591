import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { apiService } from 'src/app/_services/app.service';
import { EditDetails } from '../../../_models/editDetails';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
export interface DialogData {
    requestId: any;
    rowData: [];
}

@Component({
    selector: 'app-add-row',
    templateUrl: './add-row.component.html',
    styleUrls: ['./add-row.component.css']
})
export class AddRowComponent implements OnInit {
    pageID: any;
    ccCode: any;
    allowTypeDropDown: any;
    username: any;
    AllDropdowns: any;
    AllDropdownsMX: any;
    AuditorIdDropdown = [];
    releaseDropDown = [];
    privateLabelList = [];
    qr_statusList = [];
    accountDepartmentList = [];
    // pageID2: any;

    mxgroups = [];
    mxstatus = [];
    mxdep = [];
    MDStatusOptions = [
        'Pending Analysis',
        'Claim',
        'Well charged',
        'Client Unilateral',
        'No support found',
        'Difference in favor Supplier',
        'Manual Calculation',
        'Not relevant amount',
        'Claim other Firm'
      ];
    MXRootCauseOptions = [
        'Allowance charged minor than suppliers redemption',
        'Allowance charged major than suppliers redemption',
        'Total Omission',
        'Time period charged minor than event in POS',
        'Time period charged major than event in POS',
        'Agreement Rejected by support opportunity',
        'Provider not longer in business with WM',
        'Vendor has a mapping promotional Different',
        'OK Well Done'
        ];

    MXMarkdownTypeOptions = [
        'Rollback',
        'Price Protection (Clearance)',
        'Linksaves',
        'Coupon',
        'Free merchandise',
        'Promotions',
        'Others'
      ];
    


    constructor(public dialogRef: MatDialogRef<AddRowComponent>, @Inject(MAT_DIALOG_DATA) public datas: DialogData, public router: Router, public activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private apiservice: apiService, public translate: TranslateService) { }
    addForm: FormGroup;
    addRowData: EditDetails;
    @Output()
    ngOnInit() {
        const prompt = JSON.parse(localStorage.getItem('userinfo'));
        this.username = prompt.username;
        this.SetLang();
        this.getAllowTypesDropDown();

        this.pageID = this.datas.requestId.id;
       // console.log(this.pageID)
        this.ccCode = this.datas.requestId.countryCode;
        this.getAllDropdownValues(this.ccCode, this.pageID);
        this.getAllDropdownValuesMX(this.pageID);
        this.getColumnNames(this.datas.rowData);

        this.getAuditorList() ;
    }

    dataArray = ['ALLOW_TYPE', 'RELEASE_NUM', 'ACCOUNT_DEPARTMENT', 'PRIVATE_LABEL', 'AUDITOR_ID', 'QR_STATUS', 'START_DATE', 'END_DATE', 'CREATION_DATE', 'DATE_AUDITED', 'INITIAL_DATE', 'FINAL_DATE', 'Department', 'GROUPS', 'StatusCode', 'STATUSCODE', 'STATUS', 'AUDITOR', 'GO_DATE', 'INV_DATE', 'CLEAR_DATE', 'COMPLETE_DATE', 'Valid_From', 'Valid_To', 'COMPLETE_DT', 'Complete_DT', 'EFFECT_DATE', 'CREATE_DTE', 'CLEARING_DT', 'POSTING_DATE', 'PO_DATE','MD_STATUS', 'MD_ROOTCAUSE', 'MARKDOWN_TYPE', 'AUDITOR_NAME','DUE_DATE','EFFECTIVE_DATE'];
    newStore = ['COOP_DEDUCTED', 'USRCHG', 'AUDITOR', 'STATUS', 'AUDITOR_COMMENTS', 'INSERT_USER', 'INS_ROW', 'AUDIT_YEAR'];
    scanAllowance = ['USRCHG', 'IVA_PCT', 'IVA_DUE', 'AMT_DUE', 'AUDIT_YEAR'];
    undeducted = ['AUDIT_YEAR'];
    volume = ['AUDIT_YEAR', 'USRCHG', 'INSERT_USER', 'INS_ROW'];
    fillRate = ['AUDIT_YEAR'];
    msi = ['AUDIT_YEAR'];
    lowPrice = ['AUDIT_YEAR'];
    shortages = ['AUDIT_YEAR'];
    markdown = ['AUDIT_YEAR'];


    getColumnNames(data) {
        if (this.ccCode == 'CL') {
            const objectFrom = {};
            if (data) {
                _.each(data, (v) => {
                    if (this.pageID == 'VOLUMES') {
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.required,
                                    Validators.pattern(/^202[0-9]$/) // Allows years like 2020, 2021, 2022...
                                ])
                            ];
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                   
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }else  if (this.pageID == 'NEW-STORE') {
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.required,
                                    Validators.pattern(/^202[0-9]$/) // Allows years like 2020, 2021, 2022...
                                ])
                            ];
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                   
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }else  if (this.pageID == 'UNDED-COOPS') {
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.required,
                                    Validators.pattern(/^202[0-9]$/) // Allows years like 2020, 2021, 2022...
                                ])
                            ];
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                   
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }
                });
            }
            this.addForm = this.formBuilder.group(objectFrom);
        } else {
            const objectFrom = {};
            if (data) {
                _.each(data, (v) => {
                    if (this.pageID == 'New Stores') {
                        if (v === 'COOP_DEDUCTED' || v === 'USRCHG' || v === 'AUDITOR' || v === 'STATUS' || v === 'AUDITOR_COMMENTS' || v === 'INSERT_USER' || v === 'INS_ROW' || v === 'AUDIT_YEAR') {
                            if (v === 'AUDIT_YEAR') {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern(/^202[0-9]$/) 
                                    ])
                                ];
                            } else {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern( /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/) 
                                    ])
                                ];
                            }
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }else if (this.pageID == 'Scan Allowances') {
                        if (v === 'USRCHG' || v === 'IVA_PCT' || v === 'IVA_DUE' || v === 'AMT_DUE' || v === 'AUDIT_YEAR') {
                            
                            if (v === 'AUDIT_YEAR') {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern(/^202[0-9]$/) 
                                    ])
                                ];
                            } else {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern( /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/) 
                                    ])
                                ];
                            }
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    } else if (this.pageID == 'Advertising' || this.pageID == 'UN-DEDUCTED COOPS' || this.pageID == 'Fill Rate' || this.pageID == 'MSI' || this.pageID == 'Low Price' || this.pageID == 'Shortages') {
                        
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.required,
                                    Validators.pattern(/^202[0-9]$/) // Allows years like 2020, 2021, 2022...
                                ])
                            ];
                        } else {
                            // Add validation for other fields, allowing letters, numbers, spaces, and some special characters
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                   
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    } else if (this.pageID == 'Volume Allowances') {
                        if (v === 'AUDIT_YEAR' || v === 'USRCHG' || v === 'INSERT_USER' || v === 'INS_ROW') {
                            if (v === 'AUDIT_YEAR') {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern(/^202[0-9]$/) 
                                    ])
                                ];
                            } else {
                                
                                objectFrom[v] = [
                                    '',
                                    Validators.compose([
                                        Validators.required,
                                        Validators.pattern( /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/) 
                                    ])
                                ];
                            }
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }else if (this.pageID == 'Markdown') {
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                    Validators.required,
                                    Validators.pattern(/^202[0-9]$/) // Allows years like 2020, 2021, 2022...
                                ])
                            ];
                        } else {
                            
                            objectFrom[v] = [
                                '',
                                Validators.compose([
                                   
                                    Validators.pattern(
                                        /^[A-Za-z0-9ÁÉÍÓÚáéíóúñÑ,.-\.!'"[\] ]+$/ 
                                    )
                                ])
                            ];
                        }
                    }else if (this.pageID == 'Markdown') {
                        if (v === 'AUDIT_YEAR') {
                            objectFrom[v] = ['', Validators.required];
                        } else {
                            objectFrom[v] = [''];
                        }
                    }
                });
            }
            this.addForm = this.formBuilder.group(objectFrom);
        }
    }

    checkIsPresentAny(v) {
        const findIndex = this.dataArray.indexOf(v);
        if (findIndex === -1) {
            return true;
        } else {
            return false;
        }
    }

    checkMandetory(v) {
        if (this.pageID == 'New Stores') {
            var findIndex = this.newStore.indexOf(v);
        } else if (this.pageID == 'Scan Allowances') {
            var findIndex = this.scanAllowance.indexOf(v);
        } else if (this.pageID == 'UN-DEDUCTED COOPS') {
            var findIndex = this.undeducted.indexOf(v);
        } else if (this.pageID == 'Volume Allowances') {
            var findIndex = this.volume.indexOf(v);
        } else if (this.pageID == 'Fill Rate') {
            var findIndex = this.fillRate.indexOf(v);
        } else if (this.pageID == 'MSI') {
            var findIndex = this.msi.indexOf(v);
        } else if (this.pageID == 'Low Price') {
            var findIndex = this.lowPrice.indexOf(v);
        } else if (this.pageID == 'Shortages') {
            var findIndex = this.lowPrice.indexOf(v);
        } else if (this.pageID == 'Markdown') {
            var findIndex = this.markdown.indexOf(v);
        }
        if (findIndex === -1) {
            return false;
        } else {
            return true;
        }
    }

    removeUnderScore(v) {
        return v.replaceAll('_', ' ');
    }

    SetLang() {
        let lang = localStorage.getItem('lang');
        if (lang === null) {
            this.translate.setDefaultLang('en');
        } else {
            this.translate.use(lang);
        }
    }

    getAllowTypesDropDown() {
        this.apiservice.getAllowTypes().subscribe((response) => {
            this.allowTypeDropDown = response;
        });
    }
    getAuditorList() {
        this.apiservice.getAuditorList(this.pageID, this.ccCode).subscribe((response) => {
            this.AuditorIdDropdown = response;
        });
    }

    getAllDropdownValues(CLCode, pageID) {
        this.apiservice.getClaimsDropdowns(CLCode, pageID).subscribe((response) => {
            this.AllDropdowns = response;

            for (var i = 0; i < this.AllDropdowns.length; i++) {
                if (this.AllDropdowns[i].Lookup_Code == 'REL') {
                    this.releaseDropDown.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'PRIVATE_LBL') {
                    this.privateLabelList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'QR_STATUS') {
                    this.qr_statusList.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'ACCOUNT_DEPARTMENT') {
                    this.accountDepartmentList.push(this.AllDropdowns[i].Lookup_Values);
                }
                if (this.AllDropdowns[i].Lookup_Values == 'GROUP') {
                    this.mxgroups.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Values == 'STATUS') {
                    this.mxstatus.push(this.AllDropdowns[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdowns[i].Lookup_Code == 'Department') {
                    this.mxdep.push(this.AllDropdowns[i].Lookup_Values);
                }
            }
        });
    }

    getAllDropdownValuesMX(pageID) {
        this.apiservice.getMXDatadropdowns(pageID).subscribe((response) => {
            this.AllDropdownsMX = response;
            for (var i = 0; i < this.AllDropdownsMX.length; i++) {
                if (this.AllDropdownsMX[i].Lookup_Values == 'GROUP') {
                    this.mxgroups.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'STATUS') {
                    this.mxstatus.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Code == 'Department') {
                    this.mxdep.push(this.AllDropdownsMX[i].Lookup_Values);
                } if (this.AllDropdownsMX[i].Lookup_Values == 'MD_STATUS') {
                    this.MDStatusOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'MD_ROOTCAUSE') {
                    this.MXRootCauseOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
                if (this.AllDropdownsMX[i].Lookup_Values == 'MARKDOWN_TYPE') {
                    this.MXMarkdownTypeOptions.push(this.AllDropdownsMX[i].Lookup_Value_Meaning);
                }
               
            }
        });
    }

    onSubmit() {
        //if( this.addForm.value.AUDIT_YEAR== )
        this.addForm.value.CREATED_BY = this.username;
        let c: any = {};
        let payload: any = {};
        c = this.addForm.getRawValue();
        if (this.ccCode === 'MX') {
            if (this.pageID === 'Scan Allowances') {
                let dkey;
                for (let x in c) {
                    if (x == 'ID') {
                    } else if (x == 'RECORD_ID') {
                        payload[x] = '';
                    } else if (x == 'VERSION') {
                        payload[x] = 0;
                    } else if (x == 'INCLUDE') {
                        payload[x] = 'Y';
                    }
                    // else if(x=='IVA_DUE'){ payload[x]=defaultzero.toString() }
                    else {
                        if (x == 'StatusCode') {
                            dkey = 'STATUSCODE';
                        } else {
                            dkey = x.toUpperCase();
                        }

                        let check = c[x];

                        if (
                            dkey == 'AUDIT_YEAR' ||
                            dkey == 'GROUPS' ||
                            dkey == 'DIVISION' ||
                            dkey == 'VENDOR_NBR' ||
                            dkey == 'SAP_VENDOR' ||
                            dkey == 'AGREEMENT_NBR' ||
                            dkey == 'DEPARTMENT' ||
                            dkey == 'ITEM_NBR' ||
                            dkey == 'POS_SALES_QTY' ||
                            dkey == 'ALL_BODEGA_POS_AMT' ||
                            dkey == 'ALL_BODEGA_POS_QTY' ||
                            dkey == 'AMS_RATE' ||
                            dkey == 'ALLOW_EARNED' ||
                            dkey == 'ACCRUAL_AMT' ||
                            dkey == 'ADJUSTMENTS' ||
                            dkey == 'AMS_QTY' ||
                            dkey == 'AMS_SALES_AMT' ||
                            dkey == 'POS_SALES_AMT' ||
                            dkey == 'UPC_NBR' ||
                            dkey == 'IEPS_PCT' ||
                            dkey == 'COOP_NUMBER' ||
                            dkey == 'CLAIM_NUMBER' ||
                            dkey == 'IVA_DUE' ||
                            dkey == 'IVA_PCT' ||
                            dkey == 'AMT_DUE' ||
                            dkey == 'REASON_CODE'
                        ) {
                            if (isNaN(c[x]) == false) {
                                payload[dkey] = parseFloat(check);
                            } else {
                                if (c[x] == 'null') {
                                    if (x == 'AMT_DUE') {
                                        payload[dkey] = 0;
                                    }
                                    payload[dkey] = 0;
                                } else {
                                    payload[dkey] = parseFloat(check);
                                }
                            }
                        } else {
                            payload[dkey] = check.toString();
                        }
                        if (x == 'DEPARTMENT_NAME') {
                            payload['VERSION'] = 0;
                        } else {
                        }
                    }
                }
            } else if (this.pageID === 'New Stores') {
                for (let x in c) {
                    let check = c[x];
                    x = x.toLocaleUpperCase();
                    if (x == 'ALLOW_EARNED' || x == 'CLAIM_NUMBER' || x == 'AMTDEDUCTED' || x == 'AMT_DUE' || x == 'COOP_DEDUCTED' || x == 'IEPS' || x == 'IEPS_PCT' || x == 'IVA' || x == 'IVA_PCT' || x == 'DPT_CAT' || x == 'RECORD_ID' || x == 'SAP_NUMBER' || x == 'STORE' || x == 'VENDOR_NBR' || x == 'SEQ' || x == 'TOTAL_SHIPS' || x == 'AUDIT_YEAR' || x == 'SHIP_CHK' || x == 'VERSION') {
                        if (isNaN(c[x]) == false) {
                            payload[x] = parseFloat(check);
                        } else {
                            if (c[x] == 'null') {
                                payload[x] = 0;
                            } else {
                                payload[x] = parseFloat(check);
                            }
                        }
                    } else if (x == 'RECORD_ID') {
                        payload[x] = '';
                    } else if (x == 'version') {
                        payload[x] = 0;
                    } else if (x == 'INCLUDE') {
                        payload[x] = 'Y';
                    } else {
                        payload[x] = check.toString();
                    }
                }
            } else if (this.pageID === 'Volume Allowances') {
                for (let x in c) {
                    let check = c[x];
                    x = x.toLocaleUpperCase();
                    if (x == ' AUDIT_YEAR' || x == 'GROUP' || x == 'TIER' || x == 'SAP_VENDOR' || x == ' VENDOR_NBR' || x == 'PCT' || x == 'DEPARTMENT' || x == 'AMS_PURCHASE' || x == 'PO_PURCHASES' || x == 'ALLOW_EARNED' || x == 'ENTERED_AMT' || x == 'OTHER_DED' || x == 'AMT_DUE' || x == 'IEPS' || x == 'IVA' || x == 'IEPS_PCT' || x == 'IVA_PCT' || x == 'TOTAL_DUE' || x == 'PREVIOUS_CLAIMS' || x == 'ADJUSTMENTS' || x == 'CLAIM_NUMBER' || x == 'CATCH_RATE') {
                        if (isNaN(c[x]) == false) {
                            payload[x] = parseFloat(check);
                        } else {
                            if (c[x] == 'null') {
                                payload[x] = 0;
                            } else {
                                payload[x] = parseFloat(check);
                            }
                        }
                    } else if (x == 'RECORD_ID') {
                        payload[x] = '';
                    } else if (x == 'version') {
                        payload[x] = 0;
                    } else if (x == 'INS_ROW') {
                        payload[x] = 'Y';
                    } else if (x == 'Include') {
                        payload[x] = 'Y';
                    } else {
                        payload[x] = check.toString();
                    }
                }
            }
            else if (this.pageID === 'UN-DEDUCTED COOPS') {
                for (let x in c) {
                    let check = c[x];
                    x = x.toLocaleUpperCase();
                    if (
                        x == 'REL' ||
                        x == 'AUDIT_YEAR' ||
                        x == 'VENDOR_NBR' ||
                        x == 'SAP_VENDOR' ||
                        x == 'COOP_NBR' ||
                        x == 'RSN_CDE' ||
                        x == 'SEQ_NO' ||
                        x == 'EFFECT_DATE' ||
                        x == 'END_DATE' ||
                        x == 'DEPARTMENT' ||
                        x == 'DIV' ||
                        x == 'BASE_COOP_AMT' ||
                        x == 'BASE_AMT_DEDUCT' ||
                        x == 'ADJUSTMENTS' ||
                        x == 'BASE_AMT_DUE' ||
                        x == 'IVA_DUE' ||
                        x == 'IEPS_DUE' ||
                        x == 'AMT_DEDUCT_TAXES' ||
                        x == 'IVA_PCT' ||
                        x == 'IEPS_PCT' ||
                        x == 'SAP_DOC_NBR' ||
                        x == 'CREATE_DTE' ||
                        x == 'CLEARING_DT' ||
                        x == 'CLAIM_NUMBER' ||
                        x == 'COMPLETE_DT'
                    ) {
                        if (isNaN(c[x]) == false) {
                            payload[x] = parseFloat(check);
                        } else {
                            if (c[x] == 'null') {
                                payload[x] = 0;
                            } else {
                                payload[x] = parseFloat(check);
                            }
                        }
                    } else if (x == 'RECORD_ID') {
                        payload[x] = '';
                    } else if (x == 'version') {
                        payload[x] = 0;
                    } else {
                        payload[x] = check.toString();
                    }
                }
            }
            else if (this.pageID === 'Advertising') {
                payload.USRCHG = c.USRCHG;
                payload.AUDIT_YEAR = c.AUDIT_YEAR;
                payload.GROUP = c.GROUP;
                payload.VENDOR_NBR = c.VENDOR_NBR;
                payload.SAP_VENDOR = c.SAP_VENDOR;
                payload.VENDOR_NAME = c.VENDOR_NAME;
                payload.BRAND = c.BRAND;
                payload.DEPT = c.DEPT;
                payload.DESCRIPTION_PAG = c.DESCRIPTION_PAG;
                payload.WEBCONTENT = c.WEBCONTENT;
                payload.DIV = c.DIV;
                payload.NUM_PUBLICATION = c.NUM_PUBLICATION;
                payload.TYPE_COLLECTION = c.TYPE_COLLECTION;
                payload.ALLOW_EARNED = parseFloat(c.ALLOW_EARNED);
                payload.ENTERED_AMT = parseFloat(c.ENTERED_AMT);
                payload.OTHER_DEDUCT = parseFloat(c.OTHER_DEDUCT);
                payload.AMTDUE = parseFloat(c.AMTDUE);
                payload.IEPS_AMOUNT = parseFloat(c.IEPS_AMOUNT);
                payload.IVA_AMOUNT = parseFloat(c.IVA_AMOUNT);
                payload.TOTAL_DUE = parseFloat(c.TOTAL_DUE);
                payload.SAP_DOC_NBR = c.SAP_DOC_NBR;
                payload.IEPS_PCT = parseFloat(c.IEPS_PCT);
                payload.IVA_PCT = parseFloat(c.IVA_PCT);
                payload.COOP_NBR = parseFloat(c.COOP_NBR);
                payload.CLAIM_NUMBER = c.Claim_Number;
                payload.AUDITOR = c.AUDITOR;
                payload.COMPLETE_DT = c.COMPLETE_DT;
                payload.STATUS = c.STATUS;
                payload.NO_OF_DEDUCTION = c.NO_OF_DEDUCTION;
                payload.AUDITOR_COMMENT = c.AUDITOR_COMMENT;
                payload.INCLUDE_CHECK = c.INCLUDE_CHECK;
                payload.INCLUDE = 'Y';
                payload.VERSION = 0;
                payload.RECORD_ID = '';
            } else if (this.pageID === 'Fill Rate') {
                (payload.USRCHG = c.USRCHG),
                    (payload.AUDIT_YEAR = c.AUDIT_YEAR),
                    (payload.GROUP = c.GROUP),
                    (payload.DIVISION = c.DIVISION),
                    (payload.VENDOR_NBR = c.VENDOR_NBR),
                    (payload.VENDOR_NAME = c.VENDOR_NAME),
                    (payload.SAP_VENDOR = c.SAP_VENDOR),
                    (payload.DEPARTMENT = c.DEPARTMENT),
                    (payload.PO_SEQ = c.PO_SEQ),
                    (payload.FORMAT = c.FORMAT),
                    (payload.TOTAL_ORD_QTY = parseFloat(c.TOTAL_ORD_QTY)),
                    (payload.TOTAL_RCV_QTY = parseFloat(c.TOTAL_RCV_QTY)),
                    (payload.TOTAL_SHORT_QTY = parseFloat(c.TOTAL_SHORT_QTY)),
                    (payload.FILL_RATE_CALC_PCT = parseFloat(c.FILL_RATE_CALC_PCT)),
                    (payload.TOTAL_RETAIL_AMT_SHORT = parseFloat(c.TOTAL_RETAIL_AMT_SHORT)),
                    (payload.TOTAL_MARGIN_LOST = parseFloat(c.TOTAL_MARGIN_LOST)),
                    (payload.FILL_RATE_PCT = parseFloat(c.FILL_RATE_PCT)),
                    (payload.TOTAL_EARNED = parseFloat(c.TOTAL_EARNED)),
                    (payload.TOTAL_AMT_PAID = parseFloat(c.TOTAL_AMT_PAID)),
                    (payload.TOTAL_PENDING = parseFloat(c.TOTAL_PENDING)),
                    (payload.IEPS_DUE = parseFloat(c.IEPS_DUE)),
                    (payload.IVA_DUE = parseFloat(c.IVA_DUE)),
                    (payload.COOP_NUMBER = parseFloat(c.COOP_NUMBER)),
                    (payload.IEPS_PCT = parseFloat(c.IEPS_PCT)),
                    (payload.IVA_PCT = parseFloat(c.IVA_PCT)),
                    (payload.PREVIOUS_CLAIMS = parseFloat(c.PREVIOUS_CLAIMS)),
                    (payload.PAST_CLAIM_NUMBERS = parseFloat(c.PAST_CLAIM_NUMBERS)),
                    (payload.INTERNAL_RATE = parseFloat(c.INTERNAL_RATE)),
                    (payload.RFC = c.RFC),
                    (payload.CLAIM_NUMBER = c.Claim_Number),
                    (payload.AUDITOR = c.AUDITOR),
                    (payload.COMPLETE_DT = c.COMPLETE_DT),
                    (payload.STATUSCODE = c.STATUSCODE),
                    (payload.COMMENTS = c.COMMENTS),
                    (payload.INCLUDE = 'Y');
                (payload.VERSION = 0), (payload.RECORD_ID = '');
            }
            // else if (this.pageID === 'MSI') {
            //     (payload.USR_CHANGE = c.USR_CHANGE),
            //         (payload.AUDIT_YEAR = c.AUDIT_YEAR),
            //         (payload.VENDOR_NAME = c.VENDOR_NAME),
            //         (payload.SAP_VENDOR = c.SAP_VENDOR),
            //         (payload.VENDOR_NBR = c.VENDOR_NBR),
            //         (payload.DEPARTMENT = c.DEPARTMENT),
            //         (payload.SEQ_NO = c.SEQ_NO),
            //         (payload.FORMAT = c.FORMAT),
            //         (payload.UNITS_SOLD = parseFloat(c.UNITS_SOLD)),
            //         (payload.TOTAL_DEFERRED = parseFloat(c.TOTAL_DEFERRED)),
            //         (payload.SURCHARGE_SALES = parseFloat(c.SURCHARGE_SALES)),
            //         (payload.AGREE = c.AGREE),
            //         (payload.DEDUCTION = parseFloat(c.DEDUCTION)),
            //         (payload.DEDUCTION_CIVA = parseFloat(c.DEDUCTION_CIVA)),
            //         (payload.OTHER_DEDUCTIONS = parseFloat(c.OTHER_DEDUCTIONS)),
            //         (payload.AMT_DUE = parseFloat(c.AMT_DUE)),
            //         (payload.MSI_PCT = parseFloat(c.MSI_PCT)),
            //         (payload.IVA = parseFloat(c.IVA)),
            //         (payload.IVA_PCT = parseFloat(c.IVA_PCT)),
            //         (payload.PREVIOUS_CLAIMS = parseFloat(c.PREVIOUS_CLAIMS)),
            //         (payload.PAST_CLAIM_NUMBERS = parseFloat(c.PAST_CLAIM_NUMBERS)),
            //         (payload.AUDITOR = c.AUDITOR),
            //         (payload.COMPLETE_DT = c.COMPLETE_DT),
            //         (payload.STATUS = c.STATUS),
            //         (payload.IEPS_PCT = parseFloat(c.IEPS_PCT)),
            //         (payload.AUDITOR_COMMENTS = c.AUDITOR_COMMENTS),
            //         (payload.CLAIM_NUMBER = c.Claim_Number),
            //         (payload.RFC = c.RFC),
            //         (payload.INCLUDE = 'Y'),
            //         (payload.VERSION = 0), (payload.RECORD_ID = '');
            // }
            else if (this.pageID === 'MSI') {
                for (let x in c) {
                    let check = c[x];
                    x = x.toLocaleUpperCase();
                    if (

                        x == 'AUDIT_YEAR' ||
                        x == 'VENDOR_NBR' ||
                        x == 'SAP_VENDOR' ||
                        x == 'DEPARTMENT' ||
                        x == 'UNITS_SOLD' ||
                        x == 'TOTAL_DEFERRED' ||
                        x == 'SURCHARGE_SALES' ||
                        x == 'DEDUCTION' ||
                        x == 'DEDUCTION_CIVA' ||
                        x == 'OTHER_DEDUCTIONS' ||
                        x == 'AMT_DUE' ||
                        x == 'MSI_PCT' ||
                        x == 'IVA' ||
                        x == 'IVA_PCT' ||
                        x == 'PREVIOUS_CLAIMS' ||

                        x == 'CLAIM_NUMBER'

                    ) {
                        if (isNaN(c[x]) == false) {
                            payload[x] = parseFloat(check);
                        } else {
                            if (c[x] == 'null') {
                                payload[x] = 0;
                            } else {
                                payload[x] = parseFloat(check);
                            }
                        }
                    }
                    else if (x == 'COMPLETE_DT') {
                        if (c[x] == "") {
                            payload[x] = null;
                        } else {
                            payload[x] = (check);
                        }

                    }

                    else if (x == 'RECORD_ID') {
                        payload[x] = '';
                    } else if (x == 'VERSION') {
                        payload[x] = 0;
                    }
                    else if (x == 'INCLUDE') {
                        payload[x] = 'Y';
                    } else {
                        payload[x] = check.toString();
                    }
                }
            }
            else if (this.pageID === 'Low Price') {
                payload.USRCHG = c.USRCHG;
                payload.AUDIT_YEAR = c.AUDIT_YEAR;
                payload.RELEASE = c.RELEASE;
                payload.VENDOR_NBR = c.VENDOR_NBR;
                payload.VENDOR_NAME = c.VENDOR_NAME;
                payload.SAP_VENDOR = c.SAP_VENDOR;
                payload.DEPT = c.DEPT;
                payload.SEQ = c.SEQ;
                payload.PO_TYPE = c.PO_TYPE;
                payload.PO_EVENT = c.PO_EVENT;
                payload.PO_NBR = c.PO_NBR;
                payload.PO_DATE = c.PO_DATE;
                payload.INV_NBR = c.INV_NBR;
                payload.INV_DATE = c.INV_DATE;
                payload.INV_TOTAL_COST = c.INV_TOTAL_COST;
                payload.PO_TOTAL_COST = c.PO_TOTAL_COST;
                payload.RCVD_TOTAL_COST = c.RCVD_TOTAL_COST;
                payload.COST_PAID_TOTAL = c.COST_PAID_TOTAL;
                payload.SB_TOTAL_COST = c.SB_TOTAL_COST;
                payload.INV_COST_DUE = c.INV_COST_DUE;
                payload.IEPS_COST_DUE = c.IEPS_COST_DUE;
                payload.IVA_COST_DUE = c.IVA_COST_DUE;
                payload.TOTAL_COST_DUE = c.TOTAL_COST_DUE;
                payload.AP_TOT_AMT = c.AP_TOT_AMT;
                payload.COST_PAID_TOT_AMT = c.COST_PAID_TOT_AMT;
                payload.COST_PAID_TOT_DIFF = c.COST_PAID_TOT_DIFF;
                payload.TOTAL_SHORT_DUE = c.TOTAL_SHORT_DUE;
                payload.INV_TOTAL_AMT = c.INV_TOTAL_AMT;
                payload.PO_TOT_AMT = c.PO_TOT_AMT;
                payload.RCVD_TOT_AMT = c.RCVD_TOT_AMT;
                payload.CLAIM_NUMBER = c.CLAIM_NUMBER;
                payload.UUID = c.UUID;
                payload.RFC = c.RFC;
                payload.CLEARING_DT = c.CLEARING_DT;
                payload.CHECK_NBR = c.CHECK_NBR;
                payload.AUDITOR = c.AUDITOR;
                payload.STATUS = c.STATUS;
                payload.COMPLETE_DT = c.COMPLETE_DT != '' ? c.COMPLETE_DT : null;
                payload.COMMENTS = c.COMMENTS;
                payload.PREVIOUS_CLAIMS = c.PREVIOUS_CLAIMS;
                payload.PAST_CLAIM_NUMBERS = c.PAST_CLAIM_NUMBERS;
                payload.CATCH_RATE = c.CATCH_RATE;
                payload.INCLUDE = 'Y';
                payload.VERSION = 0;
                payload.RECORD_ID = ''; 
            }
           
            else if (this.pageID === 'Markdown') {
                payload.VENDOR_NBR  = c.VENDOR_NBR;
                payload.VNDR_NAME  = c.VNDR_NAME;
                payload.Audit_Year = c.AUDIT_YEAR;
                payload.EFFECTIVE_DATE = c.EFFECTIVE_DATE;
                payload.END_DATE = c.END_DATE;
                payload.DEPT = c.DEPT;
                payload.CAT= c.CAT;
                payload.ITEM_NUM = c.ITEM_NUM;
                payload.ITEM1_DESC = c.ITEM1_DESC;
                payload.BANNER_DESC = c.BANNER_DESC;
                payload.POS_SALES = c.POS_SALES;
                payload.POS_QTY= c.POS_QTY;
                payload['IMPACT_MU/MD'] = c['IMPACT_MU/MD'];
                payload.UNIT_RETAIL = parseFloat(c.UNIT_RETAIL);
                payload.AVG_UNIT_SALE_PRICE = c.AVG_UNIT_SALE_PRICE;
                payload.UNIT_COST = parseFloat(c.UNIT_COST);
                payload.COMMERCIAL_MARGE = parseFloat(c.COMMERCIAL_MARGE);
                payload.REAL_MARGE = parseFloat(c.REAL_MARGE);
                payload.MARGE_DEVIATION = parseFloat(c.MARGE_DEVIATION);
                payload.AMS_AGREEMENT = c.AMS_AGREEMENT;
                payload.AGREEMENT_AMOUNT = c.AGREEMENT_AMOUNT;
                payload.DIFFERENCE = c.DIFFERENCE;
                payload.MD_STATUS = c.MD_STATUS;
                payload.MD_ROOTCAUSE= c.MD_ROOTCAUSE;
                payload.MARKDOWN_TYPE = c.MARKDOWN_TYPE;
                payload.EMAIL_SUPPORT = c.EMAIL_SUPPORT;
                payload.AUDITOR_NAME = c.AUDITOR_NAME;
                payload.AUDITOR_COMMENTS = c.AUDITOR_COMMENTS;
                payload.DUE_DATE = c.DUE_DATE;
                payload.CLAIM_NUMBER = c.CLAIM_NUMBER;
                payload.SELECT_ROW = c.SELECT_ROW;
                payload.VAT = c.VAT;
                payload.IEPS = c.IEPS;
                payload.IVA_DUE = c.IVA_DUE;
                payload.IEPS_DUE = c.IEPS_DUE;
                payload.TOTAL_DUE = c.TOTAL_DUE;
                payload.UNIT_ALLOWANCE = c.UNIT_ALLOWANCE;
                payload.EVENT_DESCRIPTION = c.EVENT_DESCRIPTION;
                payload.INCLUDE = 'Y';
                payload.VERSION = 0;
                payload.RECORD_ID = ''; 
                
            }
            else if (this.pageID === 'Shortages') {
                payload.USRCHG = c.USRCHG;
                payload.AUDIT_YEAR = c.AUDIT_YEAR;
                payload.RELEASE = c.RELEASE;
                payload.VENDOR_NBR = c.VENDOR_NBR;
                payload.VENDOR_NAME = c.VENDOR_NAME;
                payload.SAP_VENDOR = c.SAP_VENDOR;
                payload.DEPT = c.DEPT;
                payload.SEQ = c.SEQ;
                payload.PO_TYPE = c.PO_TYPE;
                payload.PO_EVENT = c.PO_EVENT;
                payload.PO_NBR = c.PO_NBR;
                payload.PO_DATE = c.PO_DATE;
                payload.INV_NBR = c.INV_NBR;
                payload.INV_DATE = c.INV_DATE;
                payload.EQUIV_EACH_INV = c.EQUIV_EACH_INV;
                payload.ZERO_RCVD_CNT = c.ZERO_RCVD_CNT;
                payload.EQUIVEACHRCVD = c.EQUIVEACHRCVD;
                payload.QTY_SHORT = c.QTY_SHORT;
                payload.SHORT_DUE = c.SHORT_DUE;
                payload.IEPS_SHORT_DUE = c.IEPS_SHORT_DUE;
                payload.IVA_SHORT_DUE = c.IVA_SHORT_DUE;
                payload.TOTAL_SHORT_DUE = c.TOTAL_SHORT_DUE;
                payload.COST_PAID_TOT_DIFF = c.COST_PAID_TOT_DIFF;
                payload.NETSHORT = c.NETSHORT;
                payload.AP_TOT_AMT = c.AP_TOT_AMT;
                payload.TOT_COST_PAID_AMT = c.TOT_COST_PAID_AMT;
                payload.CLAIM_NUMBER = c.CLAIM_NUMBER;
                payload.UUID = c.UUID;
                payload.RFC = c.RFC;
                payload.CLEARING_DT = c.CLEARING_DT;
                payload.CHECK_NBR = c.CHECK_NBR;
                payload.AUDITOR = c.AUDITOR;
                payload.STATUS = c.STATUS;
                payload.COMPLETE_DT = c.COMPLETE_DT;
                payload.COMMENTS = c.COMMENTS;
                payload.PREVIOUS_CLAIMS = c.PREVIOUS_CLAIMS;
                payload.PAST_CLAIM_NUMBERS = c.PAST_CLAIM_NUMBERS;
                payload.CATCH_RATE = c.CATCH_RATE;
                payload.INCLUDE = 'Y';
                payload.VERSION = 0;
                payload.RECORD_ID = ''; 
            }
        } else {
            payload = this.addForm.value;
        }
        this.apiservice.addNewRow(payload, this.pageID, this.ccCode).subscribe((response) => {
            this.dialogRef.close();
            this.router.navigate(['assign/' + this.ccCode + '/' + this.pageID]);
            this.Refresh();
        });
    }

    Refresh() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./assign/' + this.ccCode + '/' + this.pageID], {
            relativeTo: this.activatedRoute
        });
    }
}
